import styled, { createGlobalStyle } from 'styled-components';
import { Game } from './ui/game';
import { useState } from 'react';

const games = [
    {
        name: 'Penalty Shoot-out',
        image: 'https://evoplay.games/wp-content/uploads/2021/03/PenaltyShootOut_270x270.jpg',
        src: 'https://demo.evoplay.games/demo/instant/html5/evoplay/penaltyshootout',
    },
    {
        name: 'Star Guardians',
        image: 'https://evoplay.games/wp-content/uploads/2021/09/580x580.jpg',
        src: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/starguardians',
    }
];

function App() {
    const [activeGame, setActiveGame] = useState(null);

    return (
        <>
            <GlobalStyle/>

            {!activeGame ? (
                <Container>
                    {games.map(game => (
                        <Game {...game} onClick={() => setActiveGame(game)}/>
                    ))}
                </Container>
            ) : (
                <>
                    <iframe
                        src={activeGame.src}

                        allow="fullscreen"
                        sandbox="allow-scripts allow-same-origin allow-orientation-lock"
                    />
                    <Button onClick={() => setActiveGame(null)}>Back to games list</Button>
                </>
            )}
        </>
    );
}

export default App;

const GlobalStyle = createGlobalStyle`
  body, #root {
    background: rgb(33, 33, 33);
    min-width: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  
  iframe {
    width: 100%;
    height: calc(100vh - 50px);
    border: none;
  }
`;

const Button = styled.div`
  width: 100%;
  background: #fff;
  color: #0074ff;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  padding: 25px;
`;
