import styled from 'styled-components';

export const Game = ({ onClick, image, name }) => (
    <Container onClick={onClick}>
        <CoverContainer>
            <CoverImage src={image} alt={name} />
        </CoverContainer>
        <GameInfoContainer>
            <GameText>Penalty Shoot-out</GameText>
            <PrimaryButton>Play game</PrimaryButton>
        </GameInfoContainer>
    </Container>
)

const PrimaryButton = styled.div`
  background: 0 0;
  outline: 0;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  position: relative;

  font-size: 16px;
  background: #0099fa;
  color: #fff;
  font-weight: 400;
  border: 1px solid #0099fa;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 80%;
  border-radius: 8px;
  padding: 8px;
  margin: 4px 0;
  max-width: 180px;
  
  transition: .2s ease-in-out;
  
  &:hover {
    background: #0074ff;
    border-color: #0074ff;
    text-decoration: none;
  }
`;

const GameText = styled.h3`
  margin-bottom: 8px;
  text-align: center;
  max-width: 86%;
  font-size: 20px;
  color: #fff!important;
`;

const CoverContainer = styled.div`
  overflow: hidden;
  border-radius: 12px;
  width: 100%;
  position: relative;
  height: 100%;
`;

const CoverImage = styled.img`
  display: block;
  transition: .2s ease-in;
  border-radius: 12px;
  object-fit: contain;

  width: 200px;
  height: 200px;
`;

const GameInfoContainer = styled.div`
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: .2s;
`;

const Container = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 10px;
  transition: .3s ease-in;
  position: relative;
  overflow: hidden;
  
  &:hover {
    transform: scale(1.05);
    border-radius: 10px;
    
    ${CoverImage} {
      filter: blur(5px);
      transform: scale(1.08);
      border-radius: 10px;
    }
    
    ${GameInfoContainer} {
      opacity: 1;
      border-radius: 10px;
    }
  }
`;
